// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-code-of-conduct-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/code-of-conduct.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-mdx" */),
  "component---src-pages-get-involved-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/get-involved.mdx" /* webpackChunkName: "component---src-pages-get-involved-mdx" */),
  "component---src-pages-hosting-guidelines-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/hosting-guidelines.mdx" /* webpackChunkName: "component---src-pages-hosting-guidelines-mdx" */),
  "component---src-pages-index-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-old-style-guide-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/old-style-guide.mdx" /* webpackChunkName: "component---src-pages-old-style-guide-mdx" */),
  "component---src-pages-speaker-guidelines-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/speaker-guidelines.mdx" /* webpackChunkName: "component---src-pages-speaker-guidelines-mdx" */),
  "component---src-pages-styleguide-mdx": () => import("/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/src/pages/styleguide.mdx" /* webpackChunkName: "component---src-pages-styleguide-mdx" */)
}

