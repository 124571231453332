module.exports = [{
      plugin: require('/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null,null]},
    },{
      plugin: require('/home/travis/build/design-systems-coalition-NYC/design-systems-coalition-NYC.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
